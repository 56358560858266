#msg {
    visibility: hidden;
    color: red;
    font-weight: bold;
    font-size: 22px;
    font-family: Verdana;
  }
  .audio-tag-recorder {
    display: none;
    margin-top: 20%;
    width: 100%;
  }
  .audio-tag-recorder-small {
    margin-top: 24px;
  }
  .audio-player-container-large {
    min-height: 270px;
  }
  .audio-player-container {
    position: relative;
    overflow: hidden;
    background-color: #C4C4C4;
    width: 100%;
    padding: 10px 10px 0px 10px;
  }

  .d-flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #canvas-audio-recorder {
    margin-top: 10px;
    background-color: #C4C4C4;
    width: 100%;
    max-height: 90px;
  }

  .canvas-audio-recorder-large {
    max-height: 150px !important;
  }

  .select-audio-recorder {
    height: 25px;
    margin: 0px 5px;
    display: none;
  }
  .a-audio-recorder {
    margin-left: 20px;
  }
  .app-audio-recorder {
    text-align: center;
    width: 100%;
  }
  #micSelect {
      width: 165px;
      color: black;
      transition: 0.3s;
      margin-right: 10px;
  }

  #mySidenav a {
    position: absolute;
    left: 10px;
    padding: 15px;
    width: 50px;
    height: 50px;
    text-decoration: none;
    font-size: 20px;
    color: white;
    border-radius:  500px;
  }
  #settings-player-button {
    position: absolute;
    left: 10px;
    margin: 15px;
  }

  .settings-player-button-small {
    margin: 5px 20px !important;
  }
  /* .record-button-small {
    margin: 5px 20px;
  } */

  /* #mySidenav a:focus {
    width: 180px;
  }

  #mySidenav a:focus #micSelect {
    width: 70px;
  } */

  #about {
      top: 20px;
    background-color: #001DAD;
  }

.timer-audio-recorder {
  font-size: 20px;
  font-weight: 400 !important;
}

.timer-container-audio-recording {
  justify-content: center;
  position: absolute;
  right: 26px;
  min-width: 49px !important;
}

.timer-container-small {
  right: 21px !important;
  margin-top: 5px;
}

.record-icon {
  font-size: 21px !important;
  height: 50px !important;
  width: 50px !important;
}

.stop-icon {
  height: 20px;
  width: 20px;
  background-color: white;
  display: inline-block;
  margin-right: 1px;
  margin-top: 7px;
}

.stop-icon-small {
  height: 13px;
  width: 13px;
}

.button-record-audio-player {
  position: absolute !important;
  right: 25px;
  top: 15px;
}

#audio-player-container .ant-progress-inner {
  background-color: #888888;
}

.play-Icon-audio-record {
  height: 35px;
  margin-bottom: 10px;
}

.play-Icon-audio-record-small {
  height: 22px;
  margin-bottom: 3px;
  margin-left: 3px;
}

.smartCommentFooter {
  margin-top: 30px !important;
}

.smartCommentAudioWave{
  margin-top: 30px !important;
}