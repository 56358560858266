.email-multiple-select .email-invalid-error{
    color: red;
}

.email-multiple-select .copy-and-maste-msg{
    font-size: 13px;
    color: gray;
}

.email-multiple-select .ant-select-selection__choice{
    margin-bottom: 5px;
}

.email-multiple-select .ant-form-item-with-help{
    margin-bottom: 0px !important;
}

.email-multiple-select .ant-form-item{
    margin-bottom: 0px !important;
}
.invalid-members-email{
    margin: 10px 0;
}
.invite-member{
    margin-top: 8px;
}