@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
.profile{
	height: calc(100vh - 88vh);
	position: absolute;
	bottom: 0;
	left: 0;
	min-width: 90px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.profile-image-container {
	cursor: pointer;
	height: 50px;
	width: 50px;
	opacity: 1;
	border-radius: 14px;
	margin: 8.5px 22px;
	position: relative;
	background-size: cover;
	background-repeat: no-repeat !important;
	background-position: center center !important;
	background-color: transparent !important;
}

.profile-image-container:hover {
	background-color: #9B9B9B !important;
}

.profile-avatar {
	width: 100%;
	border-radius: 14px !important;
	height: 100%;
}

.profile-bottom-image-avatar {
	background-color: #306AE1;
}

.profile-dropdown-item{
	width: 145px;
}

.profile-dropdown-item:hover {
	background-color: #001dad;
	color: #fff;
}

.sign-out{
	border-top: 1px solid #F2F2F2;
}

.online{
	position: absolute;
	background: #07FF02;
	border: 2px solid #FFFFFF;
	height: 12px;
	width: 12px;
	border-radius: 50%;
	top: 40px;
	left: 40px;
	right: 0;
	bottom: 0;
	z-index: 10;
}

.integration-container{
 	height: 600px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: flex-start;
  	margin-left: 30px;
}

.integration-column{
	padding: 8px 0;
	height: 225px;
	width: 160px;
	background: #ECF5FF;
	border-radius: 5px;
	transition: box-shadow .3s;
}

.integration-column:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}

.integration-content{
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	height: 100%;
	width: 100%;
	padding: 10px 20px 10px 20px;
}

.integration-btn{
	width: 120px;
	height: 35px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	text-align: center;
	border-radius: 5px;
	border: none;
	cursor: pointer;
}

.connect{
	background: #001DAD;
	color: #FFFFFF;
}

.disconnect{
	color: #333333;
	background: #E0E0E0;
}

.connect:hover, .connect:focus{
  background: #306AE1;
  color: #FFFFFF;
}

.disconnect:active, .disconnect:focus, .disconnect:hover{
	color: #333333 !important;
	background: #F2F2F2 !important;
}

.integration-header{
	margin: 8px 0 0 0;
	text-align: start;
	align-self: flex-start;
}

.integration-api{
	color: #001DAD;
	text-align: start;
}

.edit-profile-container{
  height: 100%;
	width: 600px;
	display: block;
	flex-direction: column;
	justify-content: center;
	align-items: center;
 	margin: auto;
	overflow-y: auto;
	overflow-x: hidden;
}

.profile-input{
	width: 350px;
	margin: 20px;
}

.profile-btn-container{
	display: flex;
	justify-content: space-between;
	width: 100%
}

.profile-btn{
	width: 145px;
	height: 45px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	text-align: center;
	border-radius: 5px;
	border: none;
	cursor: pointer;
	color: #FFFFFF;
}

.change{
	background: #001DAD;
}

.change:hover, .change:focus{
  background: #306AE1;
  color: #FFFFFF;
}

.delete{
	background: #EB5757;
	border-radius: 5px;
}

.delete:hover, .delete:focus{
	background: #e67979;
	color: #FFFFFF;
}

.edit-profile-header{
	width: 100%;
}

.err-alert{
	margin-top: 20px;
	width: 100%;
	text-align: left;
}
