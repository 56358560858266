input[type="file"]:hover {
    border-top: 0.125rem solid #ECEFF1;
}

.imagesSection{
    height: 64px !important;
}

.drop-image-preview{
    text-align: center;
    padding-top: 20px;
}

.imagesSection > div:focus{
    outline: none !important;
}

.upload-image-and-drag-drop {
    flex: 1 1;
    display: flex;
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}