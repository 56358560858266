.drawer-close-btn{
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: block;
  line-height: 1;
  text-align: center;
  padding: 20px;
  font-size: 16px;
  cursor: pointer;
  color: rgba(0, 0, 0, .45);
  transition-delay: 0s;
  transition-duration: 0.3s;
}

.drawer-close-btn:hover{
  color: rgba(0, 0, 0, .75);;
}

.tags-container{
  width: 100%;
  padding: 10px 0px 0px 60px;
}

@media only screen and (max-width: 850px) {
  .tags-container{
    width: 100%;
    padding: 10px 0px 0px 25px;
  }
}
.tag-btn{
	width: 120px;
	height: 35px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	text-align: center;
	border-radius: 5px;
	border: none;
	cursor: pointer;
  color: #FFFFFF;
}

 .add{
	background: #001DAD;
	color: #FFFFFF;

}

.add:hover, .add:focus{
  background: #306AE1;
  color: #FFFFFF;
}

.cancel{
	background: #F2F2F2;
	color: #333333;
  margin-right: 10px;
}

.cancel:hover{
	background: #ECF5FF;
  color: #333333;
}

.tag-list > li{
  height: 50px;
  border-radius: 5px;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 15px;
	margin: 0;
  transition-delay: 5ms;
  transition-duration: 5ms;
	width: 100%;
}

.tag-list > li::after {
  content: none;
}

.tag-list > li:hover{
  background: #F2F2F2 !important;
	width: 100%;
}

.tag-content{
  display: flex;
  justify-content: center;
  align-items: center;
}

.tag-btns-container{
  display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
  width: 80px;
}

.hide-edit{
  display: none;
}
.hide-delete{
  display: none;
}

.tag-list > li:hover .hide-edit{
  display: flex !important;
  align-items: center;
  color: #001DAD;
}

.tag-list > li:hover .hide-delete{
  display: flex !important;
  align-items: center;
  color: red;
}

.tag-dialog-container {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
  padding: 20px;
  min-height: 300px;
}

.tag-color{
  height: 20px;
  width: 22px;
  border-radius: 3px;
  margin-right: 10px;
}

.tag-selected-color{
  position: absolute;
  height: 20px;
  width: 22px;
  border-radius: 3px;
  z-index: 2;
  top: 50%;
  left: 20px;
  transform: translate(-50%, -50%);
}

.tag-input{
	height: 50px;
	background: #FFFFFF;
	border-radius: 5px;
  position: relative;
  padding-left: 38px !important;
}

.add-tag-btn-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}

.chips-style{
  margin-bottom: 20px;
}
