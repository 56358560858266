#radioButton .border-none{
    border: none !important;
}

#radioButton .ant-radio-group-large label.ant-radio-button-wrapper {
    background-color: inherit;
    padding: 0 5px;
}
#radioButton .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
}

#radioButton .ant-radio-button-wrapper {
    border: none;
}

#radioButton .ant-radio-button-wrapper:first-child {
    border: none;
    border-color: inherit;
}

#radioButton .ant-radio-button-wrapper:not(:first-child):before{
    display: none;
}

#radioButton .ant-radio-button-wrapper-checked:before {
    background-color: gray !important;
}

#radioButton .ant-radio-button-wrapper-checked {
    box-shadow: none;
    border-color: none;
}

#radioButton .ant-radio-button-wrapper-checked:hover {
    box-shadow: none;
    border-color: none;
}

#radioButton .ant-radio-button-wrapper-checked:focus-within {
    outline: none;
}

.ant-menu-submenu {
    flex: 0 0 0% !important;
    margin: 0;
}

.ant-menu-submenu .ant-menu-item {
    height: 45px;
    padding-bottom: 2px;
}

.d-flex {
    display: flex;
}