.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.notification-click{
  cursor: pointer;
}

.card-container {
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 1366px) {
  .card-container {
    /* overflow-y: scroll; */
    overflow-y: hidden;
    display: block;
  }
}
.frame-container {
  border: 5px solid #001DAD;
}
.frame-wrapper {
  /* width: 1366px; */
  /* margin: 10px 0px 10px 0px; */
  /* min-width: 1366px; */
}
.full-width {
  width: 100%;
}

.react-joyride__beacon:focus, .react-joyride__beacon span:focus{
  outline: none !important;
}
.react-joyride__tooltip button[aria-label=Next]{
  background-color: #001DAD !important;
}
.react-joyride__tooltip button[aria-label=Back]{
  color: #001DAD !important;
}
.react-joyride__tooltip button[aria-label=Last]{
  background-color: #001DAD !important;
}
.react-joyride__beacon :first-child{
  background-color: #001DAD !important;
}
.react-joyride__beacon :last-child{
  border: 2px solid #001DAD !important;
}

body .ant-drawer-wrapper-body{
  overflow-y: auto !important;
}

.disabled{
  color: currentColor;
  cursor: default !important;
  opacity: 0.5;
  text-decoration: none;
}

textarea.ant-input{
  height: auto !important;
  line-height: normal !important;
}

body{
  overflow-x: hidden;
}

.ant-menu-item-selected {
  color: #000000A6 !important;
  background-color: white !important;
}

.ant-menu-item-selected:hover {
  color: white !important;
}

@media only screen and (max-width: 850px) {
  .ant-tooltip {
    display: none !important;
  }
  .ant-drawer-body{
    height: unset !important;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


.chatarea__messages {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex-basis: 100%;
}


/* sender message */
.sendermessage {
  align-self: flex-end;
  background: white;
  padding: 5px;
  min-width: 100px;
  width: fit-content;
  margin: 5px 0;
  border-radius: 10px;
  margin-bottom: 2px;
  line-height: 18px;
  max-width: 80%;
  text-align: right;
  background: #e2e2e2;
  margin-bottom: 10px;
}
.sendermessage > p {
  padding: 5px;
  padding-bottom: 0px;
}


/* reciever message */
.receivermessage {
  align-self: flex-start;
  background: white;
  padding: 5px;
  min-width: 100px;
  width: fit-content;
  border-radius: 10px;
  margin-bottom: 2px;
  line-height: 18px;
  max-width: 80%;
  text-align: left;
  background: #40a9ff;
  color: #fff;
}
.receivermessage > p {
  padding: 5px;
}