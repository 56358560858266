.chat-show-resolved {
    text-align: right;
    padding: 6px;
}

.chat-window-search-input {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
}



.message-seprator {
    display: flex;
    flex: 1;
    justify-content: space-around;
    padding-top: 13px;
    border-top: 1px solid #E0E0E0;
    padding-bottom: 10px;

}
.chat-message-input {
    max-height: 250px;
    min-height: 40px;
    border: none !important;
    background: transparent;
    width: 85%;
    outline:0px !important;
    -webkit-appearance:none;
    box-shadow: none !important;
    margin-top: 25px;
}

.chat-window-time {
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 11px;
    text-align: right;
    color: #9B9B9B;
    margin-left: 10px;
}

.chat-window-name {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #636262;
}

.chat-window-message {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: #636262;
    text-align: left;
    border-radius: 2px;
}

.chat-window-message-container-header {
    padding-top: 5px;
}

.chat-window-message-container-header:hover .show-hover-element {
    display: block;
}​

/* .chat-window-hovered-background {
    background-color: red !important;
} */

/* this is not working */
.chat-window-hover-container:hover .chat-window-hovered-background {
    background-color: #FAFAFA !important;
}​

.chat-window-message-container-no-header {
    padding-top: 5px !important;
}

.chat-window-seprator {

    /* padding-top: 30px !important; */
    border-bottom: 1px solid #F2F2F2;
    transform: rotate(0.26deg);
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.chat-window-message-seprator {
    border: 1px solid #F2F2F2;
    box-sizing: border-box;
    border-radius: 10px;
    position: absolute;
    height: 34px;
    width: 80px;
    bottom: -17px;
    left: calc(50% - 40px);
    background: white;

    /* font */
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    /* align text center */
    justify-content: center;
    align-items: center;
    display: flex;
}

.c-p {
    cursor: pointer;
}

.chat-window-image-container {
    width: 100%;
    height: 106px;
    background: #FAFAFA;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.chat-window-feedback-image {
    width: 179.63px;
    height: 88.74px;
    border-radius: 10px;
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    margin-left: 10px;
}

.file-uploaded-wrapper {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 5px 5px 8px 5px;
    width: 150px !important;
}

.chat-window-audio-recorder {
    bottom: 46px;
    position: absolute;
    background-color: #C4C4C4;
    width: 300px;
}

.chat-window-hover-menu {
    position: absolute;
    right: 0;
    top: 0;
}

.chat-message-input-box {
    display: flex;
    margin-left: 15px;
}

.chat-window-feedback-button {
    border: none !important;
    align-items: flex-end !important;
    margin-bottom: 10px;
}


#chat-window-canvas-audio-recorder {
    background-color: #FFFFFF;
    max-height: 30px;
    padding: 0px;
    width: 100%;
    margin-left: 10px;
  }

.chat-window-audio-recorder-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.chat-window-audio-icon {
    font-size: 18px;
}
.m-4 {
    margin: 4px;
}

.chat-window-side-time {
    /* margin-top: -5px; */
    line-height: 14px;
}

.chat-window-canvas-audio {
    width: 100%;
    height: 106px;
    /* background-color: #FAFAFA; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.chat-window-player {
    width: 180px;
    height: 88px;
    background-color: #F2F2F2;
    border-radius: 10px;
    overflow: hidden;
}

.chat-window-canvas {
    width: 180px;
    height: 40px;
    position: absolute;
    bottom: 0;
    overflow: hidden;
}

#chat-window-audio-content {
    position: relative;
    width: 180px;
    border-radius: 5px;
    overflow: hidden;
    margin-left: 5px;
}

.chat-window-audio-player-content {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    margin-left: 5px;
}

.chat-window-audio-player-speed {
    font-size: 10px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 10px;
    font-weight: 600;
}

.chat-window-audio-timer {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    font-weight: 600;
    line-height: 11px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.chat-window-play-pause-icon {
    position: absolute;
    width: 45px;
    height: 45px;
    left: 62px;
    top: 20px;
    cursor: pointer;
}

.chat-window-play-pause-icon-responsive {
    position: absolute;
    width: 45px;
    height: 45px;
    left: calc(50% - 22px);
    top: calc(50% - 22px);
    cursor: pointer;
}

.chat-window-prev-icon {
    position: absolute;
    left: 25px;
    top: 37px;
    cursor: pointer;
}

.chat-window-prev-icon-responsive {
    position: absolute;
    left: 40px;
    top: calc(50% - 5px);
    cursor: pointer;
}

.chat-window-next-icon {
    position: absolute;
    right: 35px;
    top: 37px;
    cursor: pointer;
}

.chat-window-next-icon-responsive {
    position: absolute;
    right: 40px;
    top: calc(50% - 5px);
    cursor: pointer;
}

.chat-window-soundIcon {
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-weight: 600;
}

.chat-window-full-screen {
    cursor: pointer;
    position: absolute;
    bottom: 8px;
    right: -6px;
    font-weight: 600;
}

.chat-window-timer-audio-recorder {
    font-size: 14px;
    line-height: 16px;
    display: flex;
    margin: 0 2px 0 5px;
}

.d-none {
    display: none;
}
.d-block {
    display: block;
}

.ant-popover-inner-content {
    padding: 0px !important;
}

.chat-window-audio-tag-recorder {
  width: 100%;
}

.ant-progress-bg {
    height: 30px !important;
    border-radius: 5px !important;
}

.ant-progress-inner {
    height: 30px;
    border-radius: 5px;
    background-color: #ECF5FF;

}

.chat-window-select-audio-recorder {
    display: block;
    width: 30px;
    height: 33px;
    opacity: 0;
    position: absolute;
    top: 20px;
    cursor: pointer;
}

.chat-window-video-component {
    border-radius: 5px;
    object-fit: cover;
}

.chat-window-video-container {
    height: 175px;
    display: flex;
    justify-content: center;
}

.chat-window-video-component-recorder {
  width: 268px;
  height: 175px;
  object-fit: cover;
  border-radius: 5px;
}

.cover-full-width video {
    object-fit: cover;
}


/* .audio-record-send-btn {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    background: #001DAD;
    border-radius: 5px;
    width: 74px;
    height: 30px;
} */

.audio-record-cln-btn, .audio-record-cln-btn:focus {
    width: 74px !important;
    height: 30px !important;
    background: #F2F2F2 !important;
    color: #333333 !important;
    border: 0px !important;
    margin-right: 6px;
  }

  .audio-record-cln-btn:hover {
    color: #333333 !important;
    background: #E0E0E0 !important;
    border: 0px !important;
  }


.audio-record-send-btn, .audio-record-send-btn:focus {
    width: 74px !important;
    height: 30px !important;
    background: #001DAD !important;
    color: #fff !important;
    border: 0px !important;
    margin: 0px !important;
  }

  .audio-record-send-btn:hover {
    background: #306AE1 !important;
    border: 0px !important;
    color: #fff !important;
  }

/* .audio-record-send-btn:hover{
    background: #306AE1 !important;
}

.audio-record-cln-btn {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #333333;
    background: #E0E0E0;
    border-radius: 5px;
    width: 74px;
    height: 30px;
}

.audio-record-cln-btn:hover{
    background: #E0E0E0 !important;
} */

.chat-window-full-screen-cinema {
    position: absolute;
    left: 6px;
    top: 3px;
}

.ant-modal-confirm-btns .ant-btn,
.ant-modal-confirm-btns .ant-btn:focus {
    width: 145px !important;
    height: 45px !important;
    background: #F2F2F2 !important;
    color: #333333 !important;
    border: 0px !important;
    margin-right: 6px;
}
@media screen and (max-width: 600px) {
    .ant-modal-confirm-btns {
        display: flex;
        float: unset !important;
    }
  }

.ant-modal-confirm-btns .ant-btn:hover {
    color: #333333 !important;
    background: #E0E0E0 !important;
    border: 0px !important;
  }

  .ant-modal-confirm-btns .ant-btn-primary,
  .ant-modal-confirm-btns .ant-btn-primary:focus {
    width: 145px !important;
    height: 45px !important;
    background: #001DAD !important;
    color: #fff !important;
    border: 0px !important;
    margin: 0px !important;
  }

  .ant-modal-confirm-btns .ant-btn-primary:hover {
    background: #306AE1 !important;
    border: 0px !important;
    color: #fff !important;
  }

.ant-modal-confirm-body-wrapper {
    height: 165px;
    padding: 22px;    
    padding-top: 7px;
}

.ant-modal-confirm-btns {
    margin-top: 25px !important;
}

.ant-modal-confirm-body {
    margin-top: 15px;
}


.recordingCountDown-video {
    display: none;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    /* display: flex; */
    background-color: #9b9b9bcc;
    z-index: 999;
    align-items: center;
  }

  .recordingCountDown-video > div {
    font-size: 10rem;
    animation-name: countDownAnimationText;
    animation-iteration-count: 3;
    font-size: 4rem;
    animation-duration: 0.7s;
    width: 200px;
    text-align: center;
    height: 200px;
    color: #333;
    border-radius: 50%;
  
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    line-height: 169px;
    color: #FFFFFF !important;
    text-shadow: 3px 2px 0px #FF0000;
    text-align: center;
  }

  @keyframes countDownAnimationText {
    from {
        font-size: 13rem;
      opacity: 1;
    }

    to {
        font-size: 5rem;
      opacity: 0;
    }
  }


.recording-modal-blur-background {
    /* z-index: -1 !important;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    display: flex;
    backdrop-filter: blur(40px);
    align-items: center; */
    /* background-color: #9b9b9bcc; */
  }
