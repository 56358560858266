.canvas-detail-back{
    background: #E0E0E0;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.canvas-header-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.canvas-header-left-section {
    display: flex;
    align-items: center;
}
.mobile-canvas-controls-container {
    display: flex;
    height: 43px;
    justify-content: space-around;
    align-items: center;
}

.chat-floating-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 999;
}
.mobile-canvas-drop-down-container {
    display: flex;
    height: 43px;
    border-bottom: 1px solid #E0E0E0;
    justify-content: space-around;
    align-items: center;
}
.mobile-canvas-header {
    width: 100%;
    /* box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1); */
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);

}
.canvas-header-right-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
}

.canvas-detail-title-text{
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 5px;
    margin-right: 8px;
    border: 1px dashed transparent;
}

.canvas-detail-title-text:hover{
    border: 1px dashed gray;
}

.canvas-detail-tag{
    border-radius: 2px;
}

.project-tag-container .canvas-detail-tag {
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.project-tag-container {
    padding-right: 23px;;
}

.canvas-member-avtar li, .canvas-member-avtar li span  {
    height: 23px !important;
    width: 23px !important;
}
.canvas-member-avtar li span.ant-avatar-string {
    top: -5px;
    font-size: 12px;
    left: -1px;
    transform: inherit !important;
}

.header-area{
    padding: 8px 10px 8px 16px;
}

.canvas-share-dropdown{
    margin: 0 8px;
}

.canvas-share-dropdown button{
    background: #001DAD;
    border-radius: 5px;
    color: #ffffff;
    font-size: 16px;
    line-height: 19px;
    border: 0px;
}

.canvas-share-dropdown button:hover{
    background: #306AE1;
    outline: 0px;
    color: #fff;
    border: 0px;
}

.canvas-action-dropdown{
    background: #E0E0E0;
    border-radius: 5px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.canvas-workflow-dropdown button{
    border-radius: 5px;
    font-size: 16px;
    line-height: 19px;
    background: #F2F2F2 !important;
    color: #333333 !important;
    border: 0px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.canvas-workflow-dropdown button span{
    padding-right: 10px;
}

.canvas-workflow-dropdown button svg path{
    stroke:  #333333;
}

.canvas-workflow-dropdown button:hover{
    color: #333333 !important;
    background: #E0E0E0 !important;
    border: 0px !important;
}

@media screen and (min-width: 768px) and (max-width: 1024px){
    .canvas-header-container {
        flex-direction: column;
    }
    .antd-pro-avatar-list-avatarList ul {
        display: flex;
    }
    .canvas-detail-title {
        padding: 0 10px;
    }
}

.canvas-action-dropdown-dot{
    height: 32px;
    width: 41px;
    display: flex;
    justify-content: center;
    text-align: center;
    background: #F2F2F2 !important;
    color: #333333 !important;
    border: 0px !important;
    border-radius: 5px;
}

.canvas-action-dropdown-dot:hover{
    color: #333333 !important;
    background: #E0E0E0 !important;
    border: 0px !important;
}

.canvas-action-dropdown-dot svg{
    margin-top: 15px;
}

.workstep-menu-item-radio{
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 8px;
}

.workstep-menu-item {
    display: flex;
    align-items: center;
}

.edit-canvas-title{
    opacity: 0;
}

.canvas-detail-title:hover .edit-canvas-title{
    opacity: 1;
}

.canvas-title-edit-input:hover, .canvas-title-edit-input:focus{
    outline: 0 !important;
    border: none !important;
    box-shadow: inherit !important;
}
.canvas-title-edit-input {
    font-style: normal;
    font-weight: normal;
    color: #333333;
    padding: 0 !important;
    height: auto !important;
    margin-right: 5px;
    font-size: 16px !important;
    line-height: 19px !important;
}

.canvas-header-background-controls {
    background: #ECF5FF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    width: 339px;
    height: 32px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-right: 9px;
}

.canvas-detail-title{
display: flex;
margin-left: 10px;
}
.ant-menu-item.project-dropdown-item.ant-menu-item-active {
    color: #000;
}

.ant-menu-item.project-dropdown-item.ant-menu-item-active:hover{
    color: #fff;
}