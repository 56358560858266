.drawer-close-btn{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    display: block;
    line-height: 1;
    text-align: center;
    padding: 20px;
    font-size: 16px;
    cursor: pointer;
    color: rgba(0, 0, 0, .45);
    transition-delay: 0s;
    transition-duration: 0.3s;
  }
  
  .drawer-close-btn:hover{
    color: rgba(0, 0, 0, .75);;
  }

  .organization-dialog-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    min-height: 250px;
}

.add-organization-btn-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.edit-organization-text {
    padding-left: 10px;
}