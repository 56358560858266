.thread-item{
    width: 100%;    
    background: #ECF5FF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 5px;
    width: calc(100% - 26px);
    margin: 0 13px 7px 13px;
    padding: 10px;
}

body .thread-filter-btn{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    border: none;
    margin-bottom: 0;
}

body .thread-filter-btn svg path{
    stroke:  #333333;
}

body .thread-filter-btn svg{
    margin-left: 28px;
}

.thread-filter-container{
    display: flex;
    justify-content: space-between;
    margin: 21px 3px 17px 3px;
}

.thread-filter-container .thread-filter-btn:hover {
   color: #001DAD; 
}

.thread-filter-menu .project-dropdown-item:hover .filter-checkbox-span{
    color: #FFFFFF; 
}

body .thread-add-comment-btn{
    background: #001DAD !important;
    border-radius: 5px !important;
    border: none !important;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 0;
    margin-right: 13px;
}

.thread-add-comment-btn:hover{
    background: #306AE1 !important;
    border-radius: 5px !important;
    outline: 0px;
    color: #fff;
    border: 0px !important;;
}

.thread-add-comment-btn:focus{
    color: #fff !important;
}

.thread-avtar{
    height: 25px;
    width: 25px;
}

.thread-member-name{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #333333;
}

.thread-ago{
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    color: #828282;
}

.thread-media-time{
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    color: #4F4F4F;
}

.thread-message{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #333333;
    display: flex;
    align-items: center;
}

.thread-count{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #000000;
    flex: auto;
}

.thread-search-input{
    display: flex !important;
    height: 34px;
    margin-bottom: 30px !important;
    justify-content: center;
    align-items: center;
    margin-top: 25px !important;
}

.thread-search-input .ant-input-prefix{
    left: 10px !important;
}

.thread-search-input input{
padding-left: 40px !important;
}

.thread-search-input input:focus, .thread-search-input input:hover {
    box-shadow: none !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

.thread-add-comment-btn {
    margin-bottom: 17px;
}

.thread-user-container{
    display: flex;
}

.thread-user-right-section{
display: flex;
    flex-grow: 1;
    align-items: center;
}

.user-detail{
    text-align: left;
    margin-left: 8px;
}
.thread-message-container{
    display: flex;
    justify-content: flex-start;
    text-align: left;
    margin-top: 5px;
} 

.thread-online-member{
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: #306AE1;
    margin-right: 5px;
}

.thread-replies{
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    color: #828282;
}

.thread-item:hover{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.thread-header {
    display: flex;
    justify-content: space-between;
}

.thread-left-section, .thread-right-section {
    display: flex;
}

.thread-left-section .back-icon, .thread-right-section .more-icon {
    margin-right: 10px;
}

.selected-thread-item{
    width: 100%;
    margin: 7px 0 0 0;
    border-radius: 0px !important;
}
.selected-thread-item:hover {
    box-shadow: inherit !important;
}

.filter-checkbox-span{
    flex-grow: 1;
    padding-right: 5px;
}

li.ant-dropdown-menu-item.project-dropdown-item {
    display: flex;
    align-items: center;
}

.edit-thread{
    opacity: 0;
    padding-left: 8px;
}

.thread-message:hover .edit-thread{
    opacity: 1;
}

.thread-message-title{
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 5px;
    margin: 1px 0px;
    border: 1px dashed transparent;
}

.thread-item-section .thread-message-title:hover{
    border: 1px dashed gray;    
}

.thread-edit-input:hover, .thread-edit-input:focus{
    outline: 0 !important;
    border: none !important;
    box-shadow: inherit !important;
}
.thread-edit-input {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px !important;
    color: #333333;
    padding: 0 !important;
    height: auto !important;
    margin-right: 5px;
}

.thread-chat-container .chat-message-input-container{
    margin-right: 10px;
    margin-left: 10px;
}

.no-thread-found{
    height: 100%;
    justify-content: center;
    text-align: center;
    display: flex;
    margin: auto;
    flex-direction: column;
}

.no-thread-found svg{
    margin: 0 auto 68px auto;
}

.no-thread-found div{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #4F4F4F;
}

.total-threads-count{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    text-align: left;
    color: #4F4F4F;
    padding-bottom: 10px;
    padding-left: 15px;
}

.resolve-thread{
    background: #F2F2F2 !important;
}