.terms-and-conditions-content {
    max-height: calc(100vh - 120px);
    overflow-y: scroll;
}
.terms-and-conditions-content{
    color: #000;
    text-align: left;
    padding: 0 24px;
}
.terms-and-condition-go-back{
    display: flex;
    margin-left: 24px;
    padding-bottom: 12px;
}