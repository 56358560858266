.branding-container{
  display: flex;
  width: 100%;
  height: calc(100vh - 92px);
  color: #333333;
  overflow-x: hidden;
}

.add-organization{
  height: 300px;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: auto;
}

.add-org-detail{
  text-align: center;
}

.project-detail-container{
  width: calc(100% - 85px);
  height: 100%;
  padding: 20px;
  display: 'flex';
  flex-direction: column;
  justify-content: space-between;
}

.branding-divider{
  height: 100%;
}

.brand-image-container{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.brand-img{
	width: 50px;
	height: 50px;
	background-size: cover;
	background-repeat: no-repeat !important;
	background-position: center center !important;
	background-color: transparent;
	border-radius: 14px;
	cursor: pointer;
}

.colors{
  display: flex;
  align-items: center;
}

.branding-color{
  height: 50px;
  width: 50px;
  border: 1px solid #828282;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  cursor: pointer;
  margin-right: 6px;
}

.selected-branding-color{
  height: 50px;
  width: 50px;
  border: 3px solid #828282;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  cursor: pointer;
  margin-right: 6px;
}

.project-title-container{
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.project-detail-title{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    display: flex;
}

.edit-project-title{
    opacity: 0;
}

.project-detail-title:hover .edit-project-title{
    opacity: 1;
}

.organization-btn{
	height: 35px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	text-align: center;
	border-radius: 5px;
	border: none;
	cursor: pointer;
  color: #FFFFFF;
}

.add-org-btn{
  background: #001DAD;
}

.add-org-btn:hover, .add-org-btn:focus{
  background: #306AE1;
  color: #FFFFFF;
}

.delete-org-btn{
  background: #EB5757;
}

.delete-org-btn:focus, .delete-org-btn:hover{
	background: #c76d6d;
  color: #FFFFFF;
}

.branding-members-container{
  width: 50%;
  padding: 25px;
}

.branding-member-add{
  width: 120px;
  height: 50px;
  font-size: 18px;
  font-weight: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.color-code{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 50px;
  text-align: center;
  font-size: 11px;
  background: #ECF5FF;
}

.ant-drawer-body{
  padding: 0 !important;
  height: 100%;
}

.frams-container{
  display: flex;
  overflow-x: scroll;
}

::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: #BDBDBD !important;
}

.frams{
  display: flex;
  align-items: center;
  width: 763px;
  border: 2px solid #333333;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 8px;
}

.project-color-container{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 25px;
}

.oraginzation-list-drawer .ant-drawer-header{
  padding: 0px !important;
}

.oraginzation-list-drawer .project-selection{
  border: 0px;
}