.outer-container {
  min-height: calc(100vh - 65px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.form-container {
  width: 30rem;
  height: 100%;
  margin-top: 3% !important;
}

.head2 {
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  text-align: center;
  color: #383e53;
}

.form {
  width: 100%;
  padding-top: 2%;
}
.form-forgot {
  display: inline;
}
.outer-path-link {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.invitation-action-btn{
  padding: 10px;
}

.invitation-action-btn-container{
  display: flex;
  justify-content: center;
}

.alreadyAccountCont {
  position: absolute;
  top: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px;
}

.alreadyAccountText {
    margin-right: 15px;
    color: #fff;
    display: flex;
    white-space: nowrap;
    align-items: center;
}

.backgroud-image-signup {
  max-width: 800px;
  bottom: 0;
  margin: 0 auto;
  z-index: 0;
}

.backgroud-image-signin {
  max-width: 600px;
  position: absolute;
  bottom: -100px;
  left: -150px;
  z-index: 0;
}

.sign-in-container {
  width: 35rem;
  height: 100%;
  border: none;
}

.login-button-signup {
    border: 2px solid #fff;
    color: #fff;
    font-weight: 600;
    padding: 4px 15px 7px 15px;
    border-radius: 5px;
    margin-left: 10px;
}
.signin-button {
  padding: 10px 20px 10px 20px;
}
.login-button-signup:hover {
  background-color: #fff;
  color: #444f60;
}

.title-text-signup {
  padding: 25px;
  font-size: 2.5rem;
  padding-top: 120px;
  color: white;
  max-width: 600px;
  margin: 0 auto;
}

.detail-text-signup {
  font-size: 1.5rem;
  margin-top: 20px;
  color: white;
  max-width: 500px;
  margin: 0 auto;
}

.detail-text-signin {
  margin-top: -1.25rem;
  color: white;
  max-width: 70%;
  margin: 0 auto;
}

.sign-in {
    margin-top: 50px;
}

.subheading {
  line-height: 1;
  padding: 20px 30px;
  font-size: 14px;
  color: #a9abac;
}

.auth-scroll-form{
  height: 100vh;
  overflow-y: scroll;
}

@media screen and (max-width:767px){
  .hidden-on-mobile{
    display: none !important;
  }
  .mobile-view{
    width: 100% !important;
  }
}

.login-image{
  /* background: url(https://www.mdcc.lk/images/Software-developer.jpg); */
  height: 100vh;
  overflow: hidden;
  border-radius: 0px 10px 10px 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-text{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  width: 100%;
}

.signin-and-signup{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  width: 100%;
  font-weight: 600;
  font-size: 22px;
}


  .ant-input {
    height: 50px !important;
    padding: 25px !important;
    border-radius: 5;
    background: #ecf5ff !important;
    border: none !important;
    /* line-height: 16 !important; */
    font-size: 15 !important;
    -webkit-user-select: text !important;
    line-height: 20px !important;
  }

  .ant-input:hover{
    border: 1px solid #001DAD !important;
    border-radius: 5px !important;
    background: #FFFFFF !important;
  }

  .ant-button {
    height: 50px !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
    border-radius: 8px !important;
    padding: 20 !important;
    background-color: #001DAD !important;
    color: #FFFFFF !important;
    line-height: 14px !important;
  }

  .ant-button:hover{
    background: #306AE1 !important;
  }

  .media-btn {
    height: 50px !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: center !important;
    border-radius: 8px !important;
    padding: 20 !important;
    border: 1px solid #BDBDBD !important;
    color: #828282 !important;
  }

.media-btn:hover{
  background: #ECF5FF !important;
}

.text-left {
  text-align: left;
  margin-top: 20px;
}

.edit-profile-container{
  height: 700px;
	width: 600px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
 	margin: auto;
}

.edit-profile-container-mobile{
  display: flex;
  width: 100% !important;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  padding: 0px !important;
  margin: 0px !important;
}
.edit-profile-container-mobile .profile-input {
  width: 100% !important;    
  margin: 4px !important;
}

.profile-input{
	width: 350px;
	margin: 20px;
}

.delete-user-containger{
  display: flex;
  justify-content: center;
  align-items: center;
  background: #EB5757;
  padding: 8px;
  /* z-index: 10; */
  margin-bottom: 20px;
}
.delete-info{
  flex: 1;
  align-items: center;
}

.delete-info > p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #FFFFFF;
  text-align: left;
  margin-bottom: 0;
}

.undo-btn{
	width: 120px !important;
	height: 35px !important;
	font-family: Roboto !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 12px !important;
	line-height: 14px !important;
	text-align: center !important;
	border-radius: 5px !important;
	border: none !important;
	cursor: pointer !important;
	color: #333333 !important;
  background: #E0E0E0 !important;
}

.undo-btn:hover, .undo-btn:focus{
	background: #f2f2f2 !important;
  color: #333333 !important;
}

.success-message{
  height: 300px;
  width: 450px;
  display: flex;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  border: 1px dashed #f2f2f2 !important;
  background-color: #f2f2f2;
  color: #333333
}

.change-email-container{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-profile-btns{
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.check-button-app-sumo {
  width: 50px;
}

.edit-profile-drawer{
  padding: 0px !important;
  margin: 0px !important;
  width: 40% !important;
}

.edit-profile-drawer .profile-avatar{
  display: flex;
  justify-content: center;
}

.profile-drawer-user-quota{
  width: 60%;
  padding: 25px;
}

.profile-drawer-container .ant-drawer-body{
  display: flex !important;
}

.sign-in-mobile-header-logo {
  display: flex !important;
}

.sign-in-mobile-header-text {
  display: flex !important;
  justify-content: flex-end;

}