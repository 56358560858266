.m-container{
  position: fixed;
  width: 225px;
  height: 56px;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
