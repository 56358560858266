.masterProjectsList {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 90px;
}
.kanbanView{
  display: inline-flex;
  background-color: #ECF5FF;
  overflow-x: scroll;
}
div.kanban::-webkit-scrollbar-track {
  margin-left: 95px !important;
  background-color: white;
}
.kanban {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  background-color: #ECF5FF;
}
.kanbanHeader {
  border-bottom: 1px solid #E0E0E0;
  background-color: #ECF5FF;
  display: flex;
  width: 100%;
  position: fixed;
}
.kanbanColumnText {
    flex: 1;
    flex-grow: 1;
    text-align: left;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    position: relative;
    user-select: none;
    padding: 0px 15px 0px 18px;
}
.projectDropdownContainer {
  border-right: 1px solid #E0E0E0;
}
.kanbanSearchContainer {
  flex: 1;
  display: flex;
}
.dashboardButtonsContainer {
  width: 120px;
  padding-right: 110px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.filter-and-notification-container-kanban{
  padding-bottom: 5px;
  justify-content: flex-end;
}
.kanbanColumn {
  display: flex;
  flex-direction: column;
  margin: 8px;
}
.kanbanColumnHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-block: 10px;
  min-height: 50px;
}
.kanbanColumnControls {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
}
.batchCallBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: none;
  cursor: pointer;
}
.kanbanContainer {
  display: inline-flex;
  /* min-height: calc(100vh - 200px); */
  margin: 90px 10px 10px 0px;
  padding: 20px;
  width: calc(100vw - 110px);
  overflow-x: scroll;
}
.kanbanContainerWithoutScroll{
  width: 100% !important;
}
.kanbanContainerWithScroll{
  width: calc(100vw - 110px) !important;
}
/* .kanvan-dashboard-project-list .tile-container{
  margin: 0 7.5px 21px 10px !important;
  overflow: hidden;
} */
.canvasColunm{
  display: flex;
  padding: 200px;
  flex-direction: column;
  padding: 6px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  user-select: none;
  width: 270px;
  border: 8px;
}
.innerScroll{
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 230px);
}
.drop-zone{
  min-height: calc(100vh - 230px);
  padding: 6px;
}
.selected-column{
  border:2px solid #4388D8;
  border-radius:5px;
}
.kanbanViewAddNewCanvas{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 25px;
}