.spinner {
	text-align: center;
	border-radius: 4px;
	height: 100%;
}

.gif-loader{
	text-align: center;
    padding: 20% 0;
    z-index: 9999;
    background: url(/images/small-loader.gif);
    background-size: 80px;
    background-repeat: no-repeat;
	background-position: center center;
	height: 100%;
}

.full-height{
	height: 100%;
}