.add-member{
    height: 31px;
    width: 31px;
    border-radius: 50%;
    background-color: #ECF5FF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;
}

.add-member:hover{
	background-color: #001dad;
	color: #fff;
}

.add-member:hover svg path {
	fill: #fff;
}

.empty-member{
    margin-left: -8px;
    margin-top: 15px;
}

.members-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


body .ant-modal-header {
    border-bottom: 0;
    padding-bottom: 2px;
    padding-top: 32px;
    border-radius: 10px 10px 0 0;
}

body .ant-modal-content{
    border-radius: 10px;
}

body .ant-modal-header .ant-modal-title{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #333333;
}

body .inivite-container {
    padding-left: 35px;
    padding-right: 35px;
}
.copy-inivitation-form-item {
    margin-bottom: 0 !important;
}

.ant-modal-footer {
    width: 100%;
    padding: 30px 59px;
    border: 1px solid #E0E0E0;
    border-radius: 0 0 10px 10px;
}
.ant-modal-footer button {
    width: 100%;
}

.member-email-address, .member-email-address:hover, .member-email-address:focus {
    background: #ECF5FF;
    border-radius: 5px;
    height: 45px;
    border:none;
}

body .copy-inivitation-button{
    background: #F2F2F2;
    border: 1px dashed #000000 !important;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #333333 !important;
    width: 100%;
    height: 45px;
    margin-top: 30px;
}

body .copy-inivitation-button:hover{
    color: #333333 !important;
    background: #E0E0E0 !important;
}

.inivite-container .send-btn{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    background: #001DAD;
    border-radius: 5px;
    width: 70px;
    height: 45px;
    margin-left: 12px;
    border: 0px !important;
}

.inivite-container .send-btn:hover{
    background: #306AE1 !important;
    border-radius: 5px !important;
    outline: 0px;
    color: #fff;
    border: 0px !important;
}

body .copy-inivitation-form-item input::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #BDBDBD;
}

body .copy-inivitation-form-item input::-moz-placeholder {
	/* Firefox 19+ */
	font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #BDBDBD;
}

body .copy-inivitation-form-item input:-ms-input-placeholder {
	/* IE 10+ */
	font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #BDBDBD;
}

body .copy-inivitation-form-item input:-moz-placeholder {
	/* Firefox 18- */
	font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #BDBDBD;
}

.member-list-container{
    overflow-y: auto;
    height: 200px;
    margin: 10px 15px 15px 15px;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 12px;
}

.multiple-email-hint {
	font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 12px;
    color: #BDBDBD;
    padding-top: 9px;
 }

 .success-message-invitation {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #4F4F4F;
    padding-left: 10px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
 }

 .add-member-success-container{
    padding: 0px;
    display: flex;
    justify-content: center;
    text-align: center;
    height: 250px;
    margin: auto;
 }

 .success-container{
    display: flex;
    margin: auto;
 }

 .ant-list-item-meta-title{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #333333;
 }

 .ant-list-item-meta-description{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 11px;
    color: #BDBDBD;
    cursor: default;
 }
 .ant-list-item-meta-avatar .ant-avatar-image{
    height: 25px;
    width: 25px;
 }

 .member-list-container ul li{
     margin-top: 0px;
     margin-bottom: 0px;
     padding: 5px 0px;
     border: none;
 }

 .member-list-container h4 a, .member-list-container h4 a:hover{
    cursor: default;
    color: #333333;
}

.member-list-container h4{
    white-space: nowrap;
    width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.inivite-container .ant-form-item-control-wrapper{
    width: 100%;
    display: inline-block;
}

body .member-role-dropdown{
    border-radius: 0;
    border-width: 0;
    border-bottom: 0.5px solid #828282;
    height: auto;
    padding: 0;
    margin-bottom: 10px;
}

body .member-role-dropdown:hover {
    border-color: #828282 !important;
}

body .member-role-dropdown span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #828282;
    border: 0;
}

.member-list-container li.ant-list-item {
    border-bottom: 0;
}

.member-list-container .ant-list-item-action{
    margin-left: 0;
}
.member-list-role{
    margin-right: 13px;

}

.member-list-container ul.ant-list-items li .delete-icon, .member-list-container ul.ant-list-items li .self-user{
opacity: 0;
}


.member-list-container ul.ant-list-items li:hover .delete-icon{
    opacity: 1;
}

.memeber-list-title{
    padding-left: 18px;
}

.add-memeber-form{
    padding-left: 35px;
}
